<template>
  <div class="page">
    <span class="download-title">下载插件</span>
    <div class="items">
      <div class="item-container">
        <span>千川词霸</span>
        <div class="download-container">
          <!-- <div class="xc-img" @click="onDownload('tiktokKeyWord')"></div> -->
          <img
            src="../../assets/images/xc.png"
            class="icon-img pointer"
            @click="onDownload('tiktokKeyWord')"
          />
        </div>
      </div>
      <div class="item-container">
        <span>千川管家</span>
        <div class="download-container">
          <img
            src="../../assets/images/yj.png"
            class="icon-img pointer"
            @click="onDownload('tiktokAdData')"
          />
          <!-- <div class="yj-img" @click="onDownload('tiktokAdData')"></div> -->
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  data () {
    return {}
  },
  created () {},
  methods: {
    // ------------------ 点击方法
    onDownload (e) {
      window.open(this.$baseUrl() + 'download/' + e)
    }
    // ------------------ 请求方法
    // ------------------ 其它
  }
}
</script>
<style lang="less" scoped>
.page {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-bottom: 88px;
  .items {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-around;
    width: 100%;
  }
  .download-title {
    margin-top: 87px;
    font-size: 40px;
    font-weight: bold;
    color: #000000;
  }
  .item-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 75px;
    color: #282828;
    font-size: 20px;
    .download-container {
      width: 396px;
      height: 274px;
      background: #ffffff;
      border: 1px solid #ebebeb;
      border-radius: 14px;
      display: flex;
      justify-content: center;
      align-items: center;
      margin-top: 20px;
      .yj-img {
        height: 90px;
        width: 90px;
        background: url('../../assets/images/yj.png');
      }
      .xc-img {
        height: 90px;
        width: 90px;
        background: url('../../assets/images/xc.png');
      }
      .chrome-img {
        height: 90px;
        width: 90px;
        background: url('../../assets/images/discover/chrome.png');
      }
      &:hover {
        box-shadow: 1px 3px 92px 9px rgba(0, 14, 35, 0.11);
        .chrome-img {
          cursor: pointer;
          background: url('../../assets/images/discover/chrome_pre.png');
        }
      }
    }
  }
}
</style>
